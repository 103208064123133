import { doc, getDoc, setDoc } from 'firebase/firestore';

import { firestore } from '../Helpers/firebase';

import { BehaviorSubject } from 'rxjs';

const templateDocName = `template_tree`;

// export type Farmer = z.infer<typeof farmerSchema>;
import defaultTemplateTree from '../../assets/templates.json';

import { config } from '../../assets/config';
import { RemoteTreeData, Template } from '../Admin/Templates/template.schema';

export const templateStore$ = new BehaviorSubject<{ [key: string]: Template }>({});

export function logOutTemplateStore() {
	templateStore$.next({});
}

// if we are in multi-tenant and there is a multi-tenant defined, we give that path
function getDocPath(tenant_id: string | undefined) {
	if (tenant_id && tenant_id.length > 0) return `tenants/${tenant_id}/templates`;
	if (config.tenants.length == 1) return `tenants/${config.tenants[0].id}/templates`;

	return `templates`;
}

function validateTemplateTree(treeData: RemoteTreeData): RemoteTreeData {
	const newTree = { ...treeData };

	Object.keys(newTree.tree).forEach(key => {
		if (newTree.tree[key].variants && Array.isArray(newTree.tree[key].variants) && newTree.tree[key].variants.length === 0)
			newTree.tree[key].variants = [{ id: 0, title: 'default', template_content: newTree.tree[key].template }];
		if (newTree.tree[key].variants == undefined)
			newTree.tree[key].variants = [{ id: 0, title: 'default', template_content: newTree.tree[key].template }];
	});
	return newTree;
}

function fixOrderInTree(tree: { [id: string]: Template }): { [id: string]: Template } {
	const keys = Object.keys(tree);
	let hasOrder = true;
	keys.forEach(key => {
		if (tree[key].order == undefined) hasOrder = false;
	});

	const newTree = { ...tree };

	// if there is an order, we make sure it is counting from 0
	if (hasOrder) {
		keys.sort((a, b) => {
			const orderA = tree[a].order || 0; // to keep tsc happy
			const orderB = tree[b].order || 0; // to keep tsc happy

			if (orderA < orderB) {
				return -1;
			} else if (orderA > orderB) {
				return 1;
			} else {
				return 0;
			}
		}).forEach((key, i) => {
			newTree[key].order = i;
		});

		return newTree;
	}

	keys.sort().forEach((key, i) => {
		newTree[key].order = i;
	});

	return newTree;
}

export async function loadTemplates(tenant_id: string | undefined) {
	const docRef = doc(firestore, getDocPath(tenant_id), templateDocName);
	try {
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const remoteTreeData = validateTemplateTree(docSnap.data() as RemoteTreeData);
			templateStore$.next(fixOrderInTree(remoteTreeData.tree));
		} else {
			const validatedTree: RemoteTreeData = validateTemplateTree({
				//@ts-ignore
				tree: defaultTemplateTree,
				version: 'v' + 0,
			});
			templateStore$.next(fixOrderInTree(validatedTree.tree));
		}
	} catch (e) {
		console.log('Error loading template tree', e);
	}
}

export function removeVariantFromTemplate(tenantId: string, templatePath: string, variantId: number): Promise<void> {
	// Create document reference
	const docRef = doc(firestore, getDocPath(tenantId), templateDocName);

	// Get template tree
	return getDoc(docRef).then(doc => {
		const templateTree = doc.data() as RemoteTreeData;
		// Remove variant from template
		templateTree.tree[templatePath].variants = templateTree.tree[templatePath].variants.filter(item => item.id !== variantId);
		// Set version
		templateTree.version = 'v' + Date.now();
		// Save template tree
		return updateTemplateTree(templateTree, tenantId);
	});
}

export function updateTemplateTree(templateTreeIn: RemoteTreeData, tenant_id: string | undefined) {
	const templateTree = validateTemplateTree(templateTreeIn);

	const docRef = doc(firestore, getDocPath(tenant_id), templateDocName);

	// we store a history so we can backtrack - for future use
	const versionDocRef = doc(firestore, getDocPath(tenant_id), `${templateDocName}_bak_${templateTree.version}`);

	return setDoc(docRef, templateTree).then(() => {
		templateStore$.next(fixOrderInTree(templateTree.tree));
		setDoc(versionDocRef, templateTree);
	});
}
