import { logEvent } from 'firebase/analytics';
import { analytics } from '../Helpers/firebase';
import { dev } from '../Helpers/utils';

export function LogAnalytics(options: string = 'no_page', additionalString: string = '') {
	if (additionalString === 'start') return;
	const logString = additionalString !== '' ? `${options}__${additionalString}` : `${options}`;
	if (!dev) {
		//take it from the main thread
		setTimeout(() => {
			logEvent(analytics, logString);
		}, 100);
	}
}
