import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import '../../i18n.js';

import {
	RecaptchaVerifier,
	signInWithEmailAndPassword,
	signInWithPhoneNumber,
	updateEmail,
	updatePassword,
	type ConfirmationResult,
	type User,
} from 'firebase/auth';

import localforage from 'localforage';

import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core';
import { useHistory, useLocation } from 'react-router';
import { config } from '../../assets/config.js';
import routes from '../../constants/routes';
import { pin_code_suffix, pin_email_suffix } from '../Admin/InviteCodes/adduser.schema.js';
import { logoutAllStores } from '../Helpers/auth.js';
import { auth } from '../Helpers/firebase.js';
import { LocalSaveStates } from '../Helpers/localstorage.js';
import { sendPushNotificationToServer } from '../Helpers/push-notifications/push-notifications.js';
import { containsFourNumbersOnly, dev, formatEpochToDateTime, formatLargeNumber, formatMobileNumber } from '../Helpers/utils.js';
import { LogAnalytics } from '../Stats/ga.js';
import { loadUserDataFromFirestore, updateUserProps, userStore$ } from '../User/user.store.js';
import { LoginSchema, loginSchema } from './login-interface.js';
import { refreshToken, startTokenRefresher, tokenRefresherIsActive } from './token-refresher';

const lastUsedMobilePath = 'lastUsedMobile';

interface LoginState {
	recaptchaVerifier: undefined | RecaptchaVerifier;
	lastMobileNumber: string;
	confirmationResult: undefined | ConfirmationResult;
	isFirstRegistration: boolean;
	isValid: {
		mobileNumber: boolean;
		confirmationCode: string;
	};
	isSubmitting: boolean;
}

// xcopy /s /e /i dist ..\rescue
// xcopy /s /e /i dist ..\rescue

export function Login() {
	//
	// hooks
	//
	const { t } = useTranslation();
	const history = useHistory();
	const [presentLoading, dismissLoading] = useIonLoading();
	const [presentToast] = useIonToast();

	const initialState: LoginState = {
		recaptchaVerifier: undefined,
		lastMobileNumber: '',
		confirmationResult: undefined,
		isFirstRegistration: true,
		isValid: { mobileNumber: false, confirmationCode: '' },
		isSubmitting: false,
	};
	const [loginWithPin, setloginWithPin] = useState<boolean | undefined>(undefined);
	const [allowRedoOTP, setAllowRedoOTP] = useState(false);
	const [state, setState] = useState(initialState);
	const [presentAlert] = useIonAlert();
	const ionContent = useRef<HTMLIonContentElement>(null);
	const location = useLocation();

	const formik = useFormik<LoginSchema>({
		initialValues: {
			mobileNumber: state.lastMobileNumber,
			confirmationCode: '',
			pinCode: '',
		},
		validationSchema: toFormikValidationSchema(loginSchema),
		onSubmit: () => {},
	});

	// setup screen and handle auto login if we have a valid token
	useEffect(() => {
		LogAnalytics('Login', 'start');

		setupScreen();
		console.log('App name Login', t('app.name'));

		// autologin is giving issues - so lets keep it dev only - not even on Oima
		if (dev)
			if (auth && auth.currentUser) {
				setState({ ...state, isSubmitting: true });
				handleLogin(auth.currentUser);
			}

		if (inputRef && inputRef.current) inputRef.current.setFocus();
	}, []);

	const inputRef = useRef<HTMLIonInputElement>(null);

	//
	// variables
	//
	const inputStyle = {
		fontSize: '40px',
		letterSpacing: '24px',
		padding: '5px',
		paddingStart: '30px',
		maxWidth: '150px',
	};

	const boxStyle = {
		display: 'flex',
		justifyContent: 'center',
		border: '1px solid var(--main-color)',
		width: '200px',
	};

	const parentStyle = {
		display: 'flex',
		justifyContent: 'center',
	};

	//
	// methods
	//
	async function setupScreen() {
		setAllowRedoOTP(false);

		if (auth && auth.languageCode) auth.languageCode = 'en';

		// set the last known phone number
		const value = await localforage.getItem(lastUsedMobilePath);
		if (typeof value === 'string') {
			const newState = { ...state, lastMobileNumber: value, isFirstRegistration: false };
			setState(newState); // does not change state/cause rerender
			formik.setFieldValue('mobileNumber', value);
		}

		const valuePin = await localforage.getItem(LocalSaveStates.WANT_OTP);
		if (typeof valuePin === 'boolean' && valuePin) {
			setloginWithPin(false);
		} else {
			setloginWithPin(true);
		}
	}

	useEffect(() => {
		// set the recaptcha - relies on windows.location.reload when switch from pin to OTP login
		if (loginWithPin !== undefined && !loginWithPin)
			try {
				const recaptchaVerifier = new RecaptchaVerifier(
					'sign-in-button',
					{
						size: 'invisible',
						callback: () => {
							// reCAPTCHA solved, allow signInWithPhoneNumber.
						},
					},
					auth
				);

				setState({ ...state, recaptchaVerifier });
			} catch (e) {
				console.log('Error recaptcha initialisation', e);
				window.location.reload();
			}
	}, [loginWithPin]);

	async function sendOTP() {
		LogAnalytics('Login', 'sendOTP');

		setState({ ...state, isSubmitting: true });
		const phoneNumber = formatMobileNumber(formik.values.mobileNumber, config.countryCode);

		if (!dev && config.allowedNumbers.length > 0 && !config.allowedNumbers.includes(phoneNumber)) {
			presentToast({
				message: 'Hey there - nice you like to try us, but please contact admin to get access!',
				duration: 5000,
				position: 'middle',
			});
			setTimeout(() => {
				window.location.reload();
			}, 5000);

			return;
		}

		const appVerifier = state.recaptchaVerifier;

		if (appVerifier !== undefined) {
			// this does not trigger
			if (state.isFirstRegistration) {
				// HACK because STATE DOES NOT UPDATE??/
				const value = await localforage.getItem(lastUsedMobilePath);
				if (typeof value !== 'string') {
					const options = {
						header: t('loginpage.title'),
						//	subHeader: 'Subtitle',
						message: t('loginpage.pleasewaitotp'), //'This is an alert message.',
						buttons: [t('general.ok')],
					};
					// disabled because state does not change if a number is found....
					presentAlert(options);
				}
			}

			await presentLoading({
				message: t('loginpage.reacaptcheck'),
				duration: 50000,
			});

			// set for future usage in login, even if the number is not correct
			localforage.setItem(lastUsedMobilePath, phoneNumber);

			signInWithPhoneNumber(auth, phoneNumber, appVerifier)
				.then((confirmationResultReceived: ConfirmationResult) => {
					if (phoneNumber && !phoneNumber.startsWith('+32'))
						sendPushNotificationToServer({
							title: 'OTP login ' + config.appDomain,
							message: `User ${phoneNumber} trying OTP login`,
							userId: t('generaladmin.adminuserid'),
						}).catch(e => {
							console.log('Error PN', e);
						});

					dismissLoading();
					presentToast(t('loginpage.otpsent'), 2000);

					// SMS sent. Prompt user to type the code from the message, then sign the
					// user in with confirmationResult.confirm(code).
					setState({
						...state,
						confirmationResult: confirmationResultReceived,
						isSubmitting: false,
					});

					LogAnalytics('Login', 'sendOTP_confirmationResultReceived');

					startAllowRedoTimer();
				})
				.catch(error => {
					console.log('Error', error);
					dismissLoading();
					LogAnalytics('Login', 'sendOTP_error');

					presentToast({
						message: t('loginpage.cannotsendsms') + ` (${error.code})`,
						duration: 3000,
					});

					setTimeout(() => {
						window.location.reload();
					}, 3000);
					console.log('sendOTP SMS not sent', error);
				});
		}
	}

	function startAllowRedoTimer() {
		setTimeout(() => {
			if (!allowRedoOTP) setAllowRedoOTP(true);
		}, 15000);
	}

	async function checkConfirmationCodeAndLogin() {
		await presentLoading({
			message: t('loginpage.confchecking'),
			duration: 17000,
		});

		LogAnalytics('Login', 'checkConfirmationCodeAndLogin');
		setState({ ...state, isSubmitting: true });
		let confirmationCode = formik.values.confirmationCode;

		if (state.confirmationResult == undefined) return;
		state.confirmationResult
			.confirm(confirmationCode)
			.then(async result => {
				LogAnalytics('Login', 'checkConfirmationCodeAndLogin_ok');

				await localforage.removeItem(LocalSaveStates.WANT_OTP);

				if (state.recaptchaVerifier && state.recaptchaVerifier.clear) {
					state.recaptchaVerifier?.clear();
				}
				// User signed in successfully.
				const user = result.user;
				await handleLogin(user, true);
			})
			.catch(error => {
				// auth/invalid-verification-code
				// auth/user-disabled'
				LogAnalytics('Login', 'checkConfirmationCodeAndLogin_error');

				dismissLoading();

				presentToast({
					message: t('loginpage.confcodeerror') + ` (${error})`,
					duration: dev ? 6000 : 3000,
				});

				confirmationCode = '';
				setState({ ...state, confirmationResult: undefined });

				setTimeout(() => {
					window.location.reload();
				}, 3500);
			});
	}

	async function handleLogin(user: User, isOTP?: boolean, pinCode?: string) {
		LogAnalytics('Login', 'handleLogin');

		// set for future usage
		localforage.setItem(lastUsedMobilePath, user.phoneNumber);

		// check if we had an invitecode
		const queryParams = new URLSearchParams(location.search);
		const receivedCode = queryParams.get('invite');
		const shortCode = queryParams.get('shortcode');

		const loadedUser = await loadUserDataFromFirestore(user.uid, isOTP, pinCode);

		// we are going to harass the admin that we have a login with a push notification
		if (user.phoneNumber && loadedUser && !user.phoneNumber.startsWith('+32'))
			setTimeout(() => {
				sendPushNotificationToServer({
					title: 'Login ' + config.appDomain,
					message: `User ${loadedUser.first_name} ${loadedUser.last_name} has logged in`,
					userId: t('generaladmin.adminuserid'),
				}).catch(e => {
					console.log('Error PN', e);
				});
			}, 100);

		if (loadedUser === false && receivedCode !== 'gensis') {
			setState({ ...state, confirmationResult: undefined });
			dismissLoading();

			presentToast(t('loginpage.notallowed'), 3000);

			setTimeout(() => {
				auth.signOut().finally(async () => {
					await logoutAllStores();
					history.replace({
						pathname: routes.LOGIN,
					});
				});
			}, 3000);

			return;
		}

		// we enforce pin code setting when user logs in via OTP
		if (!loginWithPin) {
			dismissLoading();
			showSetPinAlert();
		}

		// locked out users need to go away
		if (loadedUser && !loadedUser.permissions.can_use_app) {
			presentToast('User locked from usage', 5000);

			auth.signOut().finally(async () => {
				await logoutAllStores();
				history.replace({
					pathname: routes.LOGIN,
				});
			});

			return;
		}

		// apparently we need a bit of delay
		setTimeout(() => {
			let url = routes.SIMPLE_MESSAGES;
			if (loadedUser && loadedUser.permissions.can_use_simple_message_tab) url = routes.SIMPLE_MESSAGES;
			// if (loadedUser && loadedUser.permissions.can_use_promo_tab) url = routes.PROMO;

			if (receivedCode === 'gensis' && user.phoneNumber === '+31612345678') {
				url = '/newuser?invite=' + receivedCode;
			}

			if (shortCode) {
				url = '/shortcode?url=' + url + '&shortcode=' + shortCode;
			}

			ionContent.current?.scrollToTop(0);

			// delay the reset of the login form and removal of loading controller
			setTimeout(() => {
				dismissLoading();
				setState({ ...state, isSubmitting: false, confirmationResult: undefined });
			}, 2000);

			// Check if authentication token refresher is not active
			if (!tokenRefresherIsActive()) {
				// Refresh token
				refreshToken(user);
				// Start refresher interval
				startTokenRefresher();
			}

			// and navigate
			history.replace({
				pathname: url,
			});
		}, 100);
	}

	function redoOTP() {
		auth.signOut().finally(() => {
			window.location.reload();
		});
	}

	async function resetPin() {
		//	await localforage.removeItem(LocalSaveStates.HAS_PIN_SET);
		await localforage.setItem(LocalSaveStates.WANT_OTP, true);
		auth.signOut().finally(() => {
			window.location.reload();
		});
	}

	async function handlePinLogin(pinCode?: string) {
		setState(state => {
			return { ...state, isSubmitting: true };
		});

		let pin = formik.values.pinCode;
		if (pinCode && pinCode.length == 4) {
			pin = pinCode;
		}

		const phoneNumber = formatMobileNumber(formik.values.mobileNumber, config.countryCode);
		if (typeof pin != 'string') return;

		if (!containsFourNumbersOnly(pin)) {
			formik.setFieldValue('confirmationCode', '');
			presentToast(t('profile.wrongpin') + ' no four', 3000);
			return;
		}

		await presentLoading({
			message: t('loginpage.loggingin'),
			duration: 17000,
		});

		signInWithEmailAndPassword(auth, `${phoneNumber}${pin_email_suffix}`, `${pin}${pin_code_suffix}`)
			.then(async userCredential => {
				await localforage.removeItem(LocalSaveStates.WANT_OTP);

				formik.setFieldValue('pinCode', '');

				const user = userCredential.user;
				await handleLogin(user, false, pin);
			})
			.catch(error => {
				dismissLoading();
				console.log('Error', error.code, error.message);
				formik.setFieldValue('pinCode', '');
				formik.setFieldTouched('pinCode', false);

				presentToast(t('profile.wrongpin') + error + ' ' + error.code + ' ' + error.message, 5000);

				// firebase rule has triggered - we need to debug
				if (error && error.code === 'permission-denied') presentToast('Permission denied, contact admin', 13000);
			})
			.finally(() => {
				setState(state => {
					return { ...state, isSubmitting: false };
				});
			});
	}

	function pinInput(ev: IonInputCustomEvent<InputChangeEventDetail>) {
		formik.handleChange(ev);
		if (ev && ev.detail && ev.detail.value && ev.detail.value.length === 4) {
			handlePinLogin(ev.detail.value);
		}
	}

	function showSetPinAlert() {
		// Copy of code in Profile.tsx (partially)
		presentAlert({
			header: t('profile.setpinheader'),
			// subHeader: "Important message",
			message: t('profile.setpinmessage'),
			buttons: [
				{
					text: t('general.ok'),
					handler: v => {
						const { pin } = v;

						if (typeof pin != 'string') return;

						if (!containsFourNumbersOnly(pin)) {
							presentToast({ message: t('profile.wrongpin') + ' no four', duration: 3000, position: 'bottom' });
						}

						if (pin.length == 0) {
							presentToast({ message: t('profile.wrongpin') + ' length', duration: 3000, position: 'bottom' });
						}

						if (pin && containsFourNumbersOnly(pin)) {
							const phoneNumber = auth.currentUser?.phoneNumber;

							if (phoneNumber && auth.currentUser) {
								updateEmail(auth.currentUser, `${phoneNumber}${pin_email_suffix}`)
									.then(() => {
										if (auth.currentUser) return updatePassword(auth.currentUser, `${pin}${pin_code_suffix}`);
									})
									.then(async () => {
										const user = userStore$.getValue();

										if (user && !user.has_pin) updateUserProps({ has_pin: true });
										presentToast({
											message: t('profile.pinset'),
											duration: 3000,
											position: 'bottom',
										});
									})
									.catch(e => {
										presentToast({
											message: t('profile.errorsettingpin'),
											duration: 3000,
											position: 'bottom',
										});
										console.log('Error updating pin', e);
									});
							}
						}
					},
				},
				{
					text: 'Cancel',
					role: 'cancel',
				},
			],
			inputs: [
				{
					name: 'pin',
					type: 'number',
					value: '',
					placeholder: t('profile.pinplaceholder'),
				},
			],
		});
	}

	return (
		<IonPage>
			<IonContent fullscreen className="ion-padding" ref={ionContent} style={parentStyle}>
				<br />
				<div className="logo_center">
					<span>{t('app.name')}</span>
				</div>
				{config.appConfig.systemDown && <h1 style={{ textAlign: 'center' }}>SYSTEM MAINTENANCE IN PROGRESS - SERVICE INTERRUPTED</h1>}
				<br />
				<div className="tagline">{t('loginpage.title')}</div>
				<br />
				<br />
				{initialState.lastMobileNumber !== '' && <h1>{t('loginpage.welcome_back')}</h1>}
				{!config.appConfig.systemDown && (
					<>
						<form onSubmit={formik.handleSubmit}>
							<IonList lines="full" className="ion-no-margin ion-no-padding">
								<IonItem>
									<IonLabel>{t('loginpage.mobileplaceholder')}</IonLabel>
								</IonItem>
								<IonItem>
									<IonInput
										className="ion_input"
										placeholder={String(t('loginpage.examplemobile'))}
										name="mobileNumber"
										required
										value={formik.values.mobileNumber}
										onIonChange={formik.handleChange}
										onIonBlur={formik.handleChange}
										type="tel"
										disabled={state.confirmationResult != undefined}
									/>
								</IonItem>
								{loginWithPin !== undefined && !loginWithPin && (
									<>
										<br />
										{state.confirmationResult == undefined && (
											<>
												<IonButton
													id="sign-in-button"
													className="ion-no-margin"
													expand="block"
													onClick={sendOTP}
													disabled={!formik.touched || !!formik.errors.mobileNumber || state.isSubmitting}
												>
													{t('loginpage.sendOTP')}
												</IonButton>
												<br></br> <br></br>
												<div
													className="loginpin"
													onClick={() => {
														setloginWithPin(true);
													}}
												>
													{t('loginpage.gotopinlogin')}
												</div>
											</>
										)}

										{state.confirmationResult != undefined && (
											<>
												<IonItem>
													<IonLabel>{t('loginpage.enterconfirmcode')}</IonLabel>
												</IonItem>
												<IonItem>
													{formik.values.mobileNumber === '+31612345678' && (
														<IonInput
															maxlength={6}
															type="password"
															inputmode="numeric"
															placeholder="123456"
															name="confirmationCode"
															onIonChange={formik.handleChange}
															onIonBlur={formik.handleChange}
															required
														/>
													)}

													{formik.values.mobileNumber !== '+31612345678' && (
														<IonInput
															maxlength={6}
															type="text"
															inputmode="numeric"
															placeholder="123456"
															name="confirmationCode"
															onIonChange={formik.handleChange}
															onIonBlur={formik.handleChange}
															required
														/>
													)}
												</IonItem>
												<IonButton
													className="ion-no-margin"
													expand="block"
													onClick={checkConfirmationCodeAndLogin}
													disabled={!formik.touched || !!formik.errors.confirmationCode || state.isSubmitting}
												>
													{t('loginpage.confirmConfCode')}
												</IonButton>
												<br></br> <br></br>
												<div
													className="loginpin"
													onClick={() => {
														setloginWithPin(true);
													}}
												>
													{t('loginpage.gotopinlogin')}
												</div>
											</>
										)}
										<br />
										<div className="otpcredits">{t('loginpage.OTPPowered')}</div>
										{allowRedoOTP && !state.isSubmitting && formik.values.confirmationCode.length == 0 && (
											<>
												<br></br>
												<br></br>
												<br></br>
												<IonButton className="ion-no-margin" expand="block" onClick={redoOTP}>
													{t('loginpage.tryagain')}
												</IonButton>
											</>
										)}
									</>
								)}

								{loginWithPin !== undefined && loginWithPin && (
									<>
										<br></br>
										<div style={parentStyle}>
											<div style={boxStyle}>
												<IonInput
													ref={inputRef}
													type="password"
													style={inputStyle}
													maxlength={4}
													placeholder="····"
													inputmode={'numeric'}
													name="pinCode"
													onIonChange={pinInput}
													onIonBlur={formik.handleChange}
													required
												/>
											</div>
										</div>
										<br></br>
										{!loginWithPin && (
											<IonButton
												id="sign-in-button"
												className="ion-no-margin"
												expand="block"
												onClick={() => {
													handlePinLogin();
												}}
												disabled={!formik.touched || !!formik.errors.pinCode || state.isSubmitting}
											>
												{t('loginpage.confirmpin')}
											</IonButton>
										)}
										<br></br>
										<div className="forgotpin" onClick={resetPin}>
											{t('loginpage.resetandotpagain')}
										</div>
									</>
								)}
							</IonList>
						</form>
					</>
				)}
				<br />
				<br />
				<br />
				<br />
				<br />
				<br /> <br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<div className="version">
					version:{formatLargeNumber(Number(APP_VERSION))} - {formatEpochToDateTime(Number(APP_VERSION))}cet
				</div>
			</IonContent>
		</IonPage>
	);
}
