import { TextFieldTypes } from '@ionic/core';
import { IonInput, IonItem, IonLabel, IonNote } from '@ionic/react';
import { FormikProps } from 'formik';
import { t } from 'i18next';

interface CustomInputProps {
	placeholder: string;
	name: string;
	required: boolean;
	i18entry: string;
	formik: FormikProps<any>;
	inputType: TextFieldTypes;
	maxlength?: number;
}

export const CustomIonInputItem: React.FC<CustomInputProps> = ({
	formik,
	name,
	required,
	i18entry = '',
	inputType = 'text',
	maxlength = undefined,
}) => {
	const value = formik.values[name];
	const errors = formik.errors;

	let fieldValid = errors[name] === undefined;

	// somehow we don't get a formik error when the field value is empty, even though zod.min is set
	if (required && value !== undefined && value.length === 0 && formik.touched[name] !== undefined) fieldValid = false;

	function allowEmptyT(entry: string): string {
		const trans = t(entry);
		if (trans === entry) return '';
		else return trans;
	}

	return (
		<>
			<IonItem className={!fieldValid ? 'ion-invalid' : ''}>
				<IonLabel position="stacked">{allowEmptyT(`${i18entry}.${name}`)}</IonLabel>
				<IonInput
					name={name}
					required={required}
					value={value}
					placeholder={allowEmptyT(`${i18entry}.${name}_helper`) as string}
					onIonChange={formik.handleChange}
					onIonBlur={formik.handleBlur}
					maxlength={maxlength}
					type={inputType}
				/>

				<IonNote slot="error">{allowEmptyT(`${i18entry}.${name}_error`)}</IonNote>
			</IonItem>
		</>
	);
};
