import { collection, collectionGroup, doc, getDocs, setDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../assets/config';
import { firestore } from '../Helpers/firebase';
import { increaseObjectCountMapKey } from '../Helpers/utils';
import { smsBatchToSubmitSchema } from '../SMS/sms.schema';
import { StatsKeys } from '../Stats/stats-keys';
import { categorizeNumberWithBuckets } from '../Stats/stats.helper';
import { setStatsKeyCount, setStatsKeyObject } from '../Stats/stats.store';
import { User } from '../User/user.schema';
import { updateUserProps, userStore$ } from '../User/user.store';
import { Campaign, campaignSchema } from './campaign.schema';

export const campaignsStore$ = new BehaviorSubject<Campaign[]>([]);

export function campaignMigrationCheck(rawData: any): Campaign {
	if (!rawData['template_path']) rawData['template_path'] = rawData['title'];
	if (!rawData['tenant_id']) rawData['tenant_id'] = '';

	// we migrate any non-existing tenant_id based on what is available
	const currentUser = userStore$.getValue();

	if (!rawData['tenant_id'] && config.tenants.length == 1) rawData['tenant_id'] = config.tenants[0].id;

	if (currentUser && currentUser.active_tenant_id && currentUser.active_tenant_id.length > 0) rawData['tenant_id'] = currentUser.active_tenant_id;

	const empty = campaignSchema.parse({
		smsData: smsBatchToSubmitSchema.parse({}),
	});
	return { ...empty, ...rawData } as Campaign;
}

export const loadCampaignForUser = async (user: User) => {
	const collectionRef = collection(firestore, `/users/${user.uid}/campaigns`);

	return getDocs(collectionRef)
		.then(collectionSnap => {
			let campaignlist: Campaign[] = [];
			collectionSnap.forEach(doc => {
				const data = doc.data();
				// legacy check
				if (data.smsData?.prefilledSMSMessage) {
					const campaignLoaded = campaignMigrationCheck(data);
					campaignlist = [...campaignlist, campaignLoaded];
				}
			});

			let tenant_id = '';
			if (config.tenants.length == 1) tenant_id = config.tenants[0].id;
			if (user.active_tenant_id && user.active_tenant_id.length > 0) tenant_id = user.active_tenant_id;
			if (tenant_id.length > 0) campaignlist = campaignlist.filter(campaign => campaign.tenant_id == tenant_id);

			runCampaignStats(campaignlist);

			updateUserWithCampaignList(campaignlist, user);

			campaignsStore$.next(campaignlist);
			return true;
		})
		.catch(e => {
			console.log('ERROR loading campaigns', `/users/${user.uid}/campaigns`, e);
			campaignsStore$.next([]);
			return e;
		});
};

/**
 * Updates the user object with the campaign list information.
 * It counts the number of SMS sent from each campaign and updates the user's total SMS sent count accordingly.
 * It also updates the user's total number of campaigns.
 *
 * @param campaignlist - The list of campaigns to update the user with.
 */
export function updateUserWithCampaignList(campaignlist: Campaign[], user: User) {
	if (!user) return;

	const userPropsToUpdate: Partial<Record<keyof User, any>> = {};

	// let's count the number of sms sent
	let smsSentCount = 0;
	campaignlist.forEach(campaign => {
		if (campaign.smsData) smsSentCount = smsSentCount + campaign.smsData.total_sms_sent + (campaign.sent_to_self ? 1 : 0);
	});

	if (user && user.total_sms_sent !== smsSentCount) userPropsToUpdate['total_sms_sent'] = smsSentCount;
	if (user && user.total_campaigns !== campaignlist.length) userPropsToUpdate['total_campaigns'] = campaignlist.length;

	// and then check if we need to update the user object
	if (Object.keys(userPropsToUpdate).length > 0) return updateUserProps(userPropsToUpdate);
}

/*
  per title how many
  send to self how many
  sendlist buckets 1 5 10 20 50 100 200 max

*/
export function runCampaignStats(campaignlist: Campaign[]) {
	const result = convertCampaignListIntoStats(campaignlist);

	Object.keys(result).forEach(key => {
		if (typeof result[key] == 'object') {
			setStatsKeyObject(key as StatsKeys, result[key]);
		}

		if (typeof result[key] == 'number') {
			setStatsKeyCount(key as StatsKeys, result[key] as number);
		}
	});
}

export function updateAddCampaign(campaign: Campaign): Promise<boolean> {
	const newCampaignData = { ...campaign, updatedAt: Date.now() };

	const docRef = doc(firestore, `/users/${campaign.created_by_id}/campaigns`, campaign._id);

	return setDoc(docRef, newCampaignData).then(() => {
		let newCampaignList = campaignsStore$.getValue() || [];
		newCampaignList = newCampaignList.filter(c => c._id !== newCampaignData._id);
		newCampaignList = [...newCampaignList, newCampaignData];

		const user = userStore$.getValue();
		if (user) updateUserWithCampaignList(newCampaignList, user);

		campaignsStore$.next(newCampaignList);
		return true;
	});
}

export const campaignCountBuckets = [1, 5, 10, 20, 50, 100, 200];

export function convertCampaignListIntoStats(campaignlist: Campaign[]) {
	const resultOfCount: { [key: string]: number | object } = {};

	resultOfCount[StatsKeys.CAMPAIGN_count] = campaignlist.length;

	// title
	let titleList: { [key: string]: number } = {};
	campaignlist.forEach(campaign => {
		titleList = { ...increaseObjectCountMapKey(titleList, campaign.title, 1) };
	});
	resultOfCount[StatsKeys.CAMPAIGN_title_object] = titleList;

	// send to self
	resultOfCount[StatsKeys.CAMPAIGN_msg_send_self_count] = campaignlist.filter(item => item.sent_to_self).length;

	// send count (msg sent total)
	let count = 0; // we can do this with array.reduce too
	campaignlist.forEach(campaign => {
		if (campaign.smsData) count = count + campaign.smsData.total_sms_sent + (campaign.sent_to_self ? 1 : 0);
	});
	resultOfCount[StatsKeys.CAMPAIGN_msg_count] = count;

	// send count buckets (msg sent)
	let bucketList: { [key: string]: number } = {};
	campaignlist.forEach(campaign => {
		if (campaign.smsData)
			bucketList = {
				...increaseObjectCountMapKey(
					bucketList,
					categorizeNumberWithBuckets(campaignCountBuckets, campaign.smsData.total_sms_sent + (campaign.sent_to_self ? 1 : 0)),
					1
				),
			};
	});
	resultOfCount[StatsKeys.CAMPAIGN_msg_send_count_buckets_object] = bucketList;

	return resultOfCount;
}

export function provideAllCampaignStats() {
	const collectionGroupRef = collectionGroup(firestore, 'campaigns');
	return getDocs(collectionGroupRef).then(async collectionSnap => {
		let list: Campaign[] = [];
		collectionSnap.forEach(doc => {
			list = [...list, doc.data() as Campaign];
		});

		return convertCampaignListIntoStats(list);
	});
}

export function getAllCampaignsForUserID(uid: string): Promise<Campaign[]> {
	const collectionRef = collection(firestore, `/users/${uid}/campaigns`);

	return getDocs(collectionRef).then(collectionSnap => {
		let campaignlist: Campaign[] = [];
		collectionSnap.forEach(doc => {
			const campaignLoaded = doc.data() as Campaign;
			campaignlist = [...campaignlist, campaignLoaded];
		});

		return campaignlist;
	});
}
