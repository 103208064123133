import { BehaviorSubject } from 'rxjs';
import questionDatabaseJSON from '../../assets/questions-library.json';

import { Question } from '../SMS/sms.schema';

export const questionDatabase = questionDatabaseJSON as Question[];

export const questionStore$ = new BehaviorSubject<Question[]>(questionDatabase);

export function logOutQuestionStore() {
	questionStore$.next([]);
}

// we actually don't do any remote loading of questions - function not utilised so far
export async function loadQuestions(tenant_id: string | undefined) {
	questionStore$.next(questionDatabase);
}
