import { z } from 'zod';
import { ShopType } from '../../assets/config';

export const shopSchema = z.object({
	_id: z.string().default(() => 's' + Math.random() + '-' + Date.now()),

	list_of_farmers: z.string().array().default([]),
	list_of_users: z.string().array().default([]),

	shop_name: z.string().min(4).default('My Default Shop Name'),
	email: z.optional(z.string().default('')),
	facebook_url: z.optional(z.string().default('')),
	place: z.optional(z.string().default('')),

	shop_admin: z.string().default(''),
	pnTokens: z.string().array().default([]),

	// tenant_id: z.string().default(''), - this is not populated anywhere

	list_of_actions_done: z.optional(
		z
			.object({
				id: z.string().default(() => 'sa' + Math.random() + '-' + Date.now()),
				id_of_action: z.string().default(''),
				action_title: z.string().default(''),
				date_done: z.number().default(0),
				message_sent: z.string().default(''),
			})
			.array()
			.default([])
	),

	shop_type: z.nativeEnum(ShopType).default(ShopType.AGRIBUSINESS),

	comments: z.optional(z.string().default('')),

	createdAt: z.number().default(() => Date.now()),
	updatedAt: z.number().default(() => Date.now()),
});

export type Shop = z.infer<typeof shopSchema>;
