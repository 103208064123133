import { z } from 'zod';

export enum PromoType {
	url = 'url',
	loan_lead = 'loan_lead',
	send_message = 'send_message',
}

export const promo_descriptions: { [key: string]: string } = {
	[PromoType.url]: 'Open link in new browser',
	[PromoType.loan_lead]: 'Open loan lead form',
	//	[PromoType.send_message]: 'Send message for tenant',
};

export const promotionalMessageSchema = z.object({
	id: z.number().default(() => Math.floor(Date.now() + Math.random() * 1000)),
	gaEvent: z.string().default(''),
	backgroundImageUrl: z.string().default('https://picsum.photos/200/300'),
	title: z.string().default(''),
	content: z.string().default(''),
	url: z.string().default(''),
	enabled: z.boolean().default(true),
	order: z.number().default(100000),

	type: z.nativeEnum(PromoType).default(PromoType.url),

	sms_amount: z.optional(z.string().default('')),

	payload: z.optional(z.any()),
});

export type PromotionalMessage = z.infer<typeof promotionalMessageSchema>;
