import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import * as Sentry from '@sentry/react';
import { registerSW } from 'virtual:pwa-register';
import { auth, initialiseFirebase } from './lib/Helpers/firebase';

import { BeforeInstallPromptEvent, beforeInstalledEventStore$ } from './lib/Install/install-helper';
import { updateFunction } from './lib/Install/update-helper';

import { t } from 'i18next';
import { config } from './assets/config';
import './index.css';
import { dev, presentToast } from './lib/Helpers/utils';
import LocaleContextProvider from './lib/stores/locale-context/locale-context';

// setup Sentry
if (!dev)
	Sentry.init({
		dsn: 'https://a863bd166dfff6cbe2b16286fe863760@o4506003097387008.ingest.sentry.io/4506003112067072',
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

// setup the service worker

const updateSW = registerSW({
	onNeedRefresh() {
		setTimeout(() => {
			// we don't kick the user already logged in
			if (auth === undefined) {
				presentToast('middle', t('general.pleasewait'));
				updateSW(true);
			} else if (auth && auth.currentUser === null) {
				presentToast('middle', t('general.pleasewait'));
				updateSW(true);
			}
			if (auth && auth.currentUser !== null) updateFunction.next(updateSW);
		}, 200);
	},
});

// Listen for the beforeinstallprompt event to support installation via app
window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
	event.preventDefault();
	beforeInstalledEventStore$.next(event);
});

try {
	//@ts-ignore
	if (navigator.Notification == undefined) navigator.Notification = console.log;
	//@ts-ignore
	if (window.Notification == undefined) window.Notification = console.log;
} catch (e) {
	console.log('This browser does not support notifications.');
}

initialiseFirebase(config.firebaseConfig, false);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<LocaleContextProvider>
			<App></App>
		</LocaleContextProvider>
	</React.StrictMode>
);
