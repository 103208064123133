import { logOutAdminData } from '../Admin/admin-helper';
import { stopBadgeListning } from '../Badges/badges.store';
import { logOutCropStore } from '../Crop/crop.store';
import { logOutFarmerStore } from '../Farmers/farmer.store';
import { stopTokenRefresher, tokenRefresherIsActive } from '../Login/token-refresher';
import { logOutContactUsStore } from '../Promo/ContactUs/contactus.store';
import { logOutExploreMoreStore } from '../Promo/ExploreMore/exploremore.store';
import { logOutFAQStore } from '../Promo/FAQ/faq.store';
import { logOutPromotionalMessageStore } from '../Promo/PromotionalMessage/promotionalmessage.store';
import { logOutShopStore } from '../Shop/shop.store';
import { logOutStatsStore } from '../Stats/stats.store';
import { logOutQuestionStore } from '../Templates/question.store';
import { logOutTemplateStore } from '../Templates/templates.store';
import { logOutTransactionStore } from '../Transaction/transaction.store';
import { logOutPermissionStore } from '../User/permission.store';
import { logOutUserStore } from '../User/user.store';
import { auth } from './firebase';

/**
 * * Constant to update the token validity
 * * Value is in milliseconds (ms)
 */
const config = {
	TIME_TO_EXPIRE: 3600000,
};

interface UserDetails {
	lastLoginAt?: string;
	stsTokenManager?: {
		expirationTime: string;
	};
}

export function validateAuthetication() {
	const userDetails: UserDetails | undefined = auth.currentUser?.toJSON();
	const dateToday = new Date();
	const tokenExpire = dateToday.getTime() - Number(userDetails?.lastLoginAt) > config.TIME_TO_EXPIRE;

	// for now we skip this function as it it is not clear if the token really expired for real
	// next, we need to check regularly on token validity
	return;

	/**
	 * * If token has expired, sign out current user
	 * * Force redirect to login
	 */
	if (tokenExpire) {
		// auth.signOut().finally(() => {
		//   window.location.replace("/login");
		// });
	}
}

export async function logoutAllStores() {
	// Prevent token refresh after logout
	if (tokenRefresherIsActive()) {
		stopTokenRefresher();
	}

	// we may want to put an if here
	await logOutAdminData();

	await logOutContactUsStore();
	await stopBadgeListning();
	await logOutTransactionStore();
	await logOutFarmerStore();
	await logOutShopStore();
	await logOutUserStore();
	await logOutTemplateStore();
	await logOutPermissionStore();
	await logOutStatsStore();
	await logOutQuestionStore();
	await logOutCropStore();
	await logOutFAQStore();
	await logOutExploreMoreStore();
	await logOutPromotionalMessageStore();
}
