import { BehaviorSubject } from 'rxjs';

import { Farmer } from '../Farmers/farmer.schema';
import { Shop } from '../Shop/shop.schema';
import { User } from '../User/user.schema';

import { Campaign } from '../Campaign/campaign.schema';
import { provideCollectionDataStore } from '../Helpers/load-listener';
import { shopMigrationCheck } from '../Shop/shop.store';
import { Stats } from '../Stats/stats.schema';
import { userMigrationCheck } from '../User/user.store';

export let adminAllUsers$ = new BehaviorSubject<User[] | undefined>(undefined);
export let adminAllShopsForTenant$ = new BehaviorSubject<Shop[] | undefined>(undefined);
export let adminAllFarmersForShop$ = new BehaviorSubject<Farmer[] | undefined>(undefined);
export let adminAllCampaignsForShop$ = new BehaviorSubject<Campaign[] | undefined>(undefined);
export let adminAllStatsForShop$ = new BehaviorSubject<Stats[] | undefined>(undefined);

let adminAllUserLogoutFn = () => {};
let adminAllShopsForTenantLogoutFn = () => {};
let adminAllFarmersForShopLogoutFn = () => {};
let adminAllCampaignsForShopLogoutFn = () => {};
let adminStatsForShopLogoutFn = () => {};

let loaded_tenant_id: string | undefined = undefined;
// let loaded_shop_id: string | undefined = undefined;

export function adminLoadAllUsers() {
	const allUsers = adminAllUsers$.getValue();
	if (allUsers === undefined) {
		const store = provideCollectionDataStore<User>('users', 'users', [], userMigrationCheck);
		adminAllUsers$ = store.store$ as BehaviorSubject<User[] | undefined>;
		adminAllUserLogoutFn = store.logOutFn;
	}
}

export function adminLoadShopsForTenant(tenant_id: string) {
	if (tenant_id && tenant_id.length === 0) {
		console.log('Error loading shops for tenant_id', tenant_id);
		return;
	}

	if (tenant_id !== loaded_tenant_id) {
		adminAllShopsForTenantLogoutFn();
		loaded_tenant_id = tenant_id;

		const store = provideCollectionDataStore<Shop>('shops', `/tenants/${tenant_id}/shops`, [], (shop: Shop) => {
			return shopMigrationCheck(shop);
		});
		adminAllShopsForTenant$ = store.store$ as BehaviorSubject<Shop[] | undefined>;
		adminAllShopsForTenantLogoutFn = store.logOutFn;
	}
}

export function adminLoadFarmersForShop(shop_id: string, tenant_id: string) {
	if (tenant_id && tenant_id.length === 0) {
		console.log('Error loading farmers for shop', shop_id, tenant_id);
		return;
	}

	//	if (shop_id !== loaded_shop_id) {
	adminAllFarmersForShopLogoutFn();
	//		loaded_shop_id = shop_id;

	console.log('Tenant_id', tenant_id, JSON.stringify(tenant_id, null, 2));

	const store = provideCollectionDataStore<Farmer>('farmers', `/tenants/${tenant_id}/shops/${shop_id}/farmers`, []);

	adminAllFarmersForShop$ = store.store$ as BehaviorSubject<Farmer[] | undefined>;
	adminAllFarmersForShopLogoutFn = store.logOutFn;
	//	}
}

export function adminLoadCampaignsForShop(user_id: string) {
	//	if (shop_id !== loaded_shop_id) {
	adminAllCampaignsForShopLogoutFn();
	//		loaded_shop_id = shop_id;
	const store = provideCollectionDataStore<Campaign>('campaigns', `/users/${user_id}/campaigns`, []);
	adminAllCampaignsForShop$ = store.store$ as BehaviorSubject<Campaign[] | undefined>;
	adminAllCampaignsForShopLogoutFn = store.logOutFn;
	//	}
}

export function adminLoadStatsForShop(user_id: string) {
	//	if (shop_id !== loaded_shop_id) {
	adminStatsForShopLogoutFn();
	//		loaded_shop_id = shop_id;
	const store = provideCollectionDataStore<Stats>('stats', `/users/${user_id}/stats`, []);
	adminAllStatsForShop$ = store.store$ as BehaviorSubject<Stats[] | undefined>;
	adminStatsForShopLogoutFn = store.logOutFn;
	//	}
}

export function logOutAdminData() {
	adminAllUserLogoutFn();
	adminAllShopsForTenantLogoutFn();
	adminAllFarmersForShopLogoutFn();
	adminAllCampaignsForShopLogoutFn();
	adminStatsForShopLogoutFn();
}
