import { z } from 'zod';

// export interface Shortcode {
//   redirectPath: string;
//   code: string;
// }
export const shortCodeSchema = z.object({
	redirectPath: z.string().default('/tabs/promo'),
	code: z.string().default(''),
	createdAt: z.number().default(0),
	deleted: z.boolean().default(false),
});

export type Shortcode = z.infer<typeof shortCodeSchema>;

export const validURLs = ['/tabs/farmerlist', '/tabs/promo'];
