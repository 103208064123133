import { Context, createContext, useReducer } from 'react';
import { LocaleContextInterface } from './locale-context.schema';
import { LocaleActionsEnum } from './locale-actions.enum';
import { LocaleReducerActionInterface } from './locale-reducer-action.schema';
import { useTranslation } from 'react-i18next';
import { config } from '../../../assets/config';
import localforage from 'localforage';
import { LocalSaveStates } from '../../Helpers/localstorage';
import { useRXJS } from '../../Helpers/utils';
import { updateAddUser, userStore$ } from '../../User/user.store';

const defaultState: LocaleContextInterface = {
	locale: (await localforage.getItem(LocalSaveStates.LOCALE)) || '',
	setLocale: () => {},
};

function localeReducer(state: LocaleContextInterface, action: LocaleReducerActionInterface) {
	if (action.type === LocaleActionsEnum.SET_LOCALE) {
		const locale = action.payload;

		return {
			...state,
			locale,
		};
	}

	return state;
}

export const LocaleContext: Context<LocaleContextInterface> = createContext<LocaleContextInterface>(defaultState);

export default function LocaleContextProvider({ children }: any) {
	const [localeState, localeDispatch] = useReducer(localeReducer, defaultState);
	const currentUser = useRXJS(userStore$);
	const { i18n } = useTranslation();

	function handleSetLocale(locale: string): void {
		// Set locale in the store
		localeDispatch({
			type: LocaleActionsEnum.SET_LOCALE,
			payload: locale,
		});

		// Save the locale in local forage
		localforage.setItem(LocalSaveStates.LOCALE, locale);

		// Save locale in firebase
		if (currentUser) {
			currentUser.locale = locale;
			updateAddUser(currentUser);
		}

		// Activated the locale
		i18n.changeLanguage(`${config.localeGroup}_${locale}`);
	}

	const contextValue: LocaleContextInterface = {
		locale: localeState.locale,
		setLocale: handleSetLocale,
	};

	return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
}
