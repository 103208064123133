import { heart } from 'ionicons/icons';
import { Badge, BadgeID } from './badges.schema';

export const badge_first_farmer: Badge = {
	id: BadgeID.first_farmer,
	icon: heart,
	meta: 'The first farmer',
	rule: {
		ruleFn: ruleData => {
			//@ts-ignore
			return ruleData?.farmers?.length > 0;
		},
	},
};

export const badge_fifth_farmer: Badge = {
	id: BadgeID.fifth_farmer,
	icon: heart,
	meta: 'The fifth farmer',
	rule: {
		ruleFn: ruleData => {
			//@ts-ignore
			return ruleData?.farmers?.length > 4;
		},
	},
};

export const badge_tenth_farmer: Badge = {
	id: BadgeID.tenth_farmer,
	icon: heart,
	meta: 'The tenth farmer',
	rule: {
		ruleFn: ruleData => {
			//@ts-ignore
			return ruleData?.farmers?.length > 9;
		},
	},
};

export const badge_second_login: Badge = {
	id: BadgeID.second_login,
	icon: heart,
	meta: 'Second login',
	rule: {
		ruleFn: ruleData => {
			//@ts-ignore
			return ruleData?.user?.login_count > 1;
		},
	},
};

export const badges = [badge_first_farmer, badge_fifth_farmer, badge_tenth_farmer, badge_second_login];
