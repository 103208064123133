import { z } from 'zod';

import { smsBatchToSubmitSchema } from '../SMS/sms.schema';

export enum CampaignStatus {
	active = 'active',
	finished = 'finished',
}

export const campaignSchema = z.object({
	_id: z.string().default(() => 'c' + Math.random() + '-' + Date.now()),
	shop_id: z.string().default(''),
	template_id: z.string().default(''),
	created_by_id: z.string().default(''),

	title: z.string().default(''),
	template_path: z.string().default('none'),
	sent: z.number().default(0),
	sent_to_self: z.boolean().default(false),
	smsData: smsBatchToSubmitSchema,
	smsQuestionState: z.any().optional(),

	tenant_id: z.string().default(''),

	createdAt: z.number().default(() => Date.now()),
	updatedAt: z.number().default(() => Date.now()),
	status: z.nativeEnum(CampaignStatus).default(CampaignStatus.active),
});

export type Campaign = z.infer<typeof campaignSchema>;
