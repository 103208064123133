import { Unsubscribe, User, onAuthStateChanged } from 'firebase/auth';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { auth, firestore } from './firebase';

/*

The createListDataLoaderAndSubscriber creates a listener to a document in firebase that contains an array of data

*/
export function createListDataLoaderAndSubscriber<T>(
	dataStore$: BehaviorSubject<T[]>,
	dataType: string,
	dataDocname: string,
	defaultDatabase: T[],
	docPath: string,
	validationFn?: (data: any) => T
) {
	let authUnsubscribe: (() => void) | null = null;

	const handleAuthStateChanged = (user: User | null) => {
		if (user) {
			if (!authUnsubscribe) {
				const docRef = doc(firestore, docPath, dataDocname);

				authUnsubscribe = onSnapshot(
					docRef,
					docSnap => {
						if (docSnap.exists()) {
							const remoteData = docSnap.data() as { [type: string]: T[] };
							let remoteList = remoteData[dataDocname];

							if (remoteList == undefined) {
								console.error(`Error loading ${dataType} from ${docPath} and ${dataDocname}`);
								remoteList = [];
							}

							if (validationFn) remoteList = [...remoteList.map(item => validationFn(item))];
							dataStore$.next(remoteList);
						} else {
							dataStore$.next(defaultDatabase);
						}
					},
					error => {
						console.log(`Error loading ${dataType}`, error);
					}
				);
			}
		} else {
			if (authUnsubscribe) {
				authUnsubscribe();
				authUnsubscribe = null;
			}
			dataStore$.next([]);
		}
	};

	return () => {
		handleAuthStateChanged(null);
		return onAuthStateChanged(auth, handleAuthStateChanged);
	};
}

/*

provideListDataStore returns the CRUD functions to be listening to realtime data stored in Firebase and/or a default
database provided for if there isn't anything in the remote database

*/

export function provideListDataStore<T>(
	dataType: string,
	dataDocName: string,
	defaultDataBase: T[],
	docPath: string,
	validationFn?: (data: any) => T
): {
	store$: BehaviorSubject<T[]>;
	newListFn: (newList: T[]) => Promise<void>;
	logOutFn: () => void;
} {
	let authUnsubscribe: Unsubscribe | undefined = undefined;
	const store$ = new BehaviorSubject<T[]>([]);

	authUnsubscribe = createListDataLoaderAndSubscriber<T>(store$, dataType, dataDocName, defaultDataBase, docPath, validationFn)();

	async function saveNewList(newList: T[]): Promise<void> {
		if (!auth.currentUser) console.warn('Saving list but not authenticated?');
		const docRef = doc(firestore, docPath, dataDocName);
		return setDoc(docRef, { [dataDocName]: newList });
	}

	return {
		store$,
		newListFn: saveNewList,
		logOutFn: () => {
			if (authUnsubscribe) authUnsubscribe();
			store$.next([]);
		},
	};
}

/*

Document based realtime listening

*/
export function createDocDataLoaderAndSubscriber<T>(
	dataStore$: BehaviorSubject<T | undefined>,
	dataType: string,
	dataDocname: string,
	defaultDatabase: T | undefined,
	docPath: string,
	validationFn?: (data: any) => T
) {
	let authUnsubscribe: (() => void) | null = null;

	const handleAuthStateChanged = (user: User | null) => {
		if (user) {
			if (!authUnsubscribe) {
				const docRef = doc(firestore, docPath, dataDocname);

				authUnsubscribe = onSnapshot(
					docRef,
					docSnap => {
						if (docSnap.exists()) {
							let remoteData: T = docSnap.data() as T;
							if (remoteData == undefined) {
								console.error(`Error loading ${dataType} from ${docPath} and ${dataDocname}`);
								remoteData = {} as T;
							}

							if (validationFn) remoteData = validationFn(remoteData);
							dataStore$.next(remoteData);
						} else {
							dataStore$.next(defaultDatabase);
						}
					},
					error => {
						console.log(`Error loading ${dataType}`, error);
					}
				);
			}
		} else {
			if (authUnsubscribe) {
				authUnsubscribe();
				authUnsubscribe = null;
			}
			dataStore$.next(undefined);
		}
	};

	return () => {
		handleAuthStateChanged(null);
		return onAuthStateChanged(auth, handleAuthStateChanged);
	};
}

export function provideDocDataStore<T>(
	dataType: string,
	dataDocName: string,
	defaultDataBase: T | undefined,
	docPath: string,
	validationFn?: (data: any) => T
): {
	store$: BehaviorSubject<T | undefined>;
	updateDocFn: (newDoc: T) => Promise<void>;
	logOutFn: () => void;
} {
	let authUnsubscribe: Unsubscribe | undefined = undefined;
	const store$ = new BehaviorSubject<T | undefined>(undefined);

	authUnsubscribe = createDocDataLoaderAndSubscriber<T>(store$, dataType, dataDocName, defaultDataBase, docPath, validationFn)();

	async function saveNewDoc(newDoc: T): Promise<void> {
		if (!auth.currentUser) {
			console.warn('Saving doc but not authenticated?');
			return;
		}
		const docRef = doc(firestore, docPath, dataDocName);
		return await setDoc(docRef, newDoc as { [x: string]: any }, { merge: true });
	}

	return {
		store$,
		updateDocFn: saveNewDoc,
		logOutFn: () => {
			if (authUnsubscribe) authUnsubscribe();
			store$.next(undefined);
		},
	};
}

/*

createCollectionDataLoaderAndSubscriber - to listen to a collection and publish when there are changes

*/
export function createCollectionDataLoaderAndSubscriber<T>(
	dataStore$: BehaviorSubject<T[]>,
	dataType: string,
	dataCollectionname: string,
	defaultDatabase: T[],
	validationFn?: (data: any) => T
) {
	let authUnsubscribe: (() => void) | null = null;

	const handleAuthStateChanged = (user: User | null) => {
		if (user) {
			if (!authUnsubscribe) {
				const collectionRef = collection(firestore, dataCollectionname);
				authUnsubscribe = onSnapshot(
					collectionRef,
					colSnap => {
						let remoteList: T[] = [];
						if (colSnap.empty) remoteList = defaultDatabase;
						if (!colSnap.empty)
							colSnap.forEach(docSnap => {
								if (docSnap.exists()) {
									const remoteData = docSnap.data() as T;

									if (remoteData == undefined) {
										console.error(`Error loading ${dataType} from ${dataCollectionname}`);
									}

									if (remoteData !== undefined)
										remoteList = [...remoteList, validationFn === undefined ? remoteData : validationFn(remoteData)];
								}
							});

						dataStore$.next(remoteList);
					},
					error => {
						console.log(`Error loading ${dataType}`, error);
					}
				);
			}
		} else {
			if (authUnsubscribe) {
				authUnsubscribe();
				authUnsubscribe = null;
			}
			dataStore$.next([]);
		}
	};

	return () => {
		handleAuthStateChanged(null);
		return onAuthStateChanged(auth, handleAuthStateChanged);
	};
}

export function provideCollectionDataStore<T>(
	dataType: string,
	dataCollectionName: string,
	defaultDataBase: T[],
	validationFn?: (data: any) => T
): {
	store$: BehaviorSubject<T[]>;
	newListFn: (newList: T[]) => Promise<void>;
	logOutFn: () => void;
} {
	let authUnsubscribe: Unsubscribe | undefined = undefined;
	const store$ = new BehaviorSubject<T[]>([]);

	authUnsubscribe = createCollectionDataLoaderAndSubscriber<T>(store$, dataType, dataCollectionName, defaultDataBase, validationFn)();

	async function saveNewList(newList: T[]): Promise<void> {
		if (!auth.currentUser) console.warn('Saving list but not authenticated?');
		console.error('Saving to collection not supported yet', newList);
	}

	return {
		store$,
		newListFn: saveNewList,
		logOutFn: () => {
			if (authUnsubscribe) authUnsubscribe();
			store$.next([]);
		},
	};
}
