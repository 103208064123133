import { z } from 'zod';
import { ShopType } from '../../../assets/config';

export const pin_email_suffix = '@dummy.com';
export const pin_code_suffix = 'pin_suffix';

export const addUserSchema = z.object({
	id: z.string().default(() => 'au' + Math.random() + '-' + Date.now()),
	created: z.number(),
	sms_starting_balance: z.number(),
	created_by: z.string(),
	phone_number: z.string(),
	message: z.string(),
	first_name: z.string(),
	last_name: z.string(),
	server_processed: z.optional(z.number().default(0)),
	uid: z.optional(z.string().default('')),
	shop_name: z.string().min(4).default('My Default Shop Name'),
	shop_type: z.nativeEnum(ShopType).default(ShopType.AGRIBUSINESS),
	tenant_id: z.string().default(''),
	pin: z
		.string()
		.min(4)
		.default('')
		.refine(value => /^\d+$/.test(value), {
			message: 'Pin must only contain numbers',
		}),
});

export type AddUser = z.infer<typeof addUserSchema>;
