import { AwardedBadge, RuleData } from './badges.schema';

import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { farmersStore$ } from '../Farmers/farmer.store';
import { shopStore$ } from '../Shop/shop.store';
import { statsStore$ } from '../Stats/stats.store';
import { updateUserProps, userStore$ } from '../User/user.store';
import { badges } from './badges';

export const awardedBadgesStore$ = new BehaviorSubject<AwardedBadge[]>([]);

let awardListener: Subscription | undefined = undefined;

function transposeBadges(awardedBadges: AwardedBadge[]): { [key: string]: AwardedBadge } {
	return awardedBadges.reduce((acc, badge) => {
		//@ts-ignore
		acc[badge.id] = badge;
		return acc;
	}, {});
}

export function initBadgeListener() {
	return;
	// set the listener for data changes - only the relevant ones
	if (!awardListener)
		awardListener = combineLatest({
			farmers: farmersStore$,
			user: userStore$,
			shops: shopStore$,
		}).subscribe(res => {
			if (res.user) {
				runBadgeCheck();
			}

			// just to be sure - even though this must happen at logout
			if (!res.user && awardListener) awardListener.unsubscribe();
		});
}

export function runBadgeCheck() {
	return;
	const awardedBadges = awardedBadgesStore$.getValue();
	const awardedBadgesMap = transposeBadges(awardedBadges);

	const badgeDataMap: RuleData = {
		farmers: farmersStore$.getValue(),
		user: userStore$.getValue(),
		stats: statsStore$.getValue(),
		shops: shopStore$.getValue(),
	};

	badges.forEach(badge => {
		let newListAwarded = [...awardedBadges];
		let hasNewAward = false;

		if (!awardedBadgesMap[badge.id]) {
			const ruleResult = badge.rule.ruleFn(badgeDataMap);

			if (ruleResult) {
				newListAwarded = [...newListAwarded, { id: badge.id, awardedDate: Date.now() }];
				hasNewAward = true;
			}
		}

		if (hasNewAward) {
			updateUserProps({ awarded_badges: newListAwarded });
			awardedBadgesStore$.next(newListAwarded);
		}
	});
}

export function stopBadgeListning() {
	return;
	if (awardListener) awardListener?.unsubscribe();
}
