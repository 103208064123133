import { z } from 'zod';
import { Farmer } from '../Farmers/farmer.schema';

export interface TextInputPayload {
	headeri18nTag: string;
}

export interface AnswerOption {
	answerID: string;
	answerType: 'default' | 'textinput' | 'free_text' | 'farmer-selector' | 'sms-confirm' | 'sms-finished';
	answerIcon: string;
	answeri18nTag: string;
	answerMeta: string;
	followUpQuestionID: string;
	payloadKeys: string[];
	payload?: TextInputPayload;
	tenant_id: string;
	important_message?: string;
	disabled?: boolean;
	criteria?: string[]; // is used in QuestionComponent function filterCritera
}

export interface Question {
	questionID: string;
	questioni18nTag: string;
	questionMeta: string;
	answerOptions: AnswerOption[];
}

export interface Path {
	questionID: string;
	answerID: string;
}

export interface Payload {
	[key: string]: string | { [key: string]: boolean } | boolean | undefined | { [key: string]: boolean }[] | Farmer[];

	free_text?: string;

	sendToSelf?: boolean;

	selectedFarmers?: {
		[key: string]: boolean;
	};

	allFarmers?: Farmer[];

	smsTemplatePath?: string;
	smsTemplateText?: string;
}

export interface Answers {
	path: Path[];
	payload: Payload;
}

// export const smsToSubmitSchema = z.object({
//   _id: z.string().default(() => "u" + Math.random() + "-" + Date.now()),
//   i: z.number().default(0),
//   date: z.number().default(0),
//   dateHumanReadable: z.string().default(""),
//   total_sms_sent: z.number().default(0),
//   shortcut: z.string().default(""),
//   message: z.string().default(""),
//   phoneNumbers: z.string().default(""),
//   uid: z.string().default(""),
//   full_name: z.string().default(""),
//   shop_name: z.string().default(""),
//   user_mobile_number: z.string().default(""),
//   first_names: z.optional(z.array(z.string().default(""))),
//   tenant_id: z.string().default(""),
// });

// export type SMSToSubmit2 = z.infer<typeof smsToSubmitSchema>;

export const smsBatchToSubmitSchema = z.object({
	_id: z.string().default(() => 'u' + Math.random() + '-' + Date.now()),
	uid: z.string().default(''),
	date: z.number().default(0),
	prefilledSMSMessage: z.string().default(''),

	sendList: z
		.array(
			z.object({
				_id: z.string(),
				mobile_number: z.string(),
				payload: z.any(),
			})
		)
		.default([]),
	sendToSelfMessage: z
		.union([
			z.boolean(),
			z.object({
				message: z.string(),
				mobile_number: z.string(),
			}),
		])
		.default(false),
	dateHumanReadable: z.string().default(''),

	shortcut: z.string().default(''),

	tenant_id: z.string().default(''),

	full_name: z.string().default(''),
	shop_name: z.string().default(''),
	shop_type: z.string().default(''),
	user_mobile_number: z.string().default(''),
	total_sms_sent: z.number().default(0),
});

export type SMSBatchToSubmit = z.infer<typeof smsBatchToSubmitSchema>;
