import { z } from 'zod';
import { awardedBadgeSchema } from '../Badges/badges.schema';
import { permissionsSchema } from './permission.schema';
import { walletSchema } from './wallet.schema';

export const roleOptions = ['storeowner', 'admin', 'frontend'];

export const userSchema = z.object({
	uid: z.string().default(() => 'u' + Math.random() + '-' + Date.now()),

	is_new: z.boolean().default(false),

	first_name: z.string().min(2).default('John'),
	last_name: z.string().min(2).default('Doe'),
	stores: z.string().array().default([]),
	role: z.string().default(roleOptions[0]),
	locale: z.string().default(''),

	phoneNumber: z.string().default(''),
	pnTokens: z.string().array().default([]),

	permissions: permissionsSchema,
	wallet: walletSchema,

	awarded_badges: z.optional(z.array(awardedBadgeSchema)).default([]),

	created: z.number().default(() => Date.now()), // too bad - this should have been createdAt
	updated: z.number().default(() => Date.now()), // too bad - this should have been updatedAt

	// all sorts of stats we want to keep in the user object
	last_login: z.number().default(() => Date.now()),
	last_100_logins: z.array(z.number()).default([]),
	login_count: z.number().default(0),
	otp_usage_count: z.number().default(0),
	total_campaigns: z.number().default(0),
	total_sms_sent: z.number().default(0),
	last_campaign_sent: z.number().default(0),

	version_installed: z.number().default(0),
	platforms: z.optional(z.array(z.string()).default([])),
	userAgent: z.optional(z.string().default('')),

	// add a property to store a list of user events. Each event consists of epoch date, event name, and event data
	user_events: z.array(z.string()).default([]),

	// pin related stuff, where we eventually do not want to store the pin in the user object
	pin: z.optional(z.string().default('')),
	has_pin: z.boolean().default(false),

	active_tenant_id: z.string().default(''),
	tenants: z.array(z.string().default('')).default([]),

	agreedTermsOn: z.number().default(0),
});

export type User = z.infer<typeof userSchema>;
