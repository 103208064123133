import { t } from 'i18next';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../../assets/config';
import { provideDocDataStore } from '../../Helpers/load-listener';
import { User } from '../../User/user.schema';
import { ContactUs, ContactUsEnum } from './contactus.schema';

export const contactUsSampleDatabase: ContactUs = {
	id: 0,
	gaEvent: 'contact_us',
	title: t('promo.support'),
	phone_number: t('promo.supportnr'),
	type: ContactUsEnum.whatsapp,
};

function getDocPath(tenant_id: string | undefined) {
	if (tenant_id && tenant_id.length > 0) return `tenants/${tenant_id}/contactus`;
	return `contactus`;
}

const dataType = 'contactus';
const dataDocName = 'contactus_doc';

export let updateContactUs = async (newContactus: ContactUs): Promise<void> => {};
export let logOutContactUsStore = () => {};
export let contactUsStore$ = new BehaviorSubject<ContactUs | undefined>(contactUsSampleDatabase);

export function loadContactUsForUserAndSubscribe(user: User) {
	let tenant_id = '';
	if (config.tenants.length == 1) tenant_id = config.tenants[0].id;
	if (user.active_tenant_id) tenant_id = user.active_tenant_id;

	const dataStore = provideDocDataStore<ContactUs>(dataType, dataDocName, contactUsSampleDatabase, getDocPath(tenant_id));

	updateContactUs = dataStore.updateDocFn;
	contactUsStore$ = dataStore.store$;
	logOutContactUsStore = async () => {
		dataStore.logOutFn;
	};
}

export function saveStatsToRemote() {
	const curStats = contactUsStore$.getValue();
	if (curStats) updateContactUs(curStats);
}
