import { collection, doc, getDocs, writeBatch } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../assets/config';
import { firestore } from '../Helpers/firebase';
import { deepEqual, dev } from '../Helpers/utils';
import { shopStore$ } from '../Shop/shop.store';
import { User } from '../User/user.schema';
import { userStore$ } from '../User/user.store';
import { StatsKeys } from './stats-keys';
import { formatNumberWithLeadingZero, getWeekNumberFromEpoch, getYearFromEpoch, getYearMonthDayFromEpoch } from './stats.helper';
import { Stats } from './stats.schema';

export const statsStore$ = new BehaviorSubject<Stats>({});
export const logOutStatsStore = () => {
	statsStore$.next({});
};

let statsLoadedFromServer: Stats = {};

export function loadStatsForUserAndSubscribe(user: User) {
	if (!config.appConfig.experimental) return;

	const collectionRef = collection(firestore, `/users/${user.uid}/stats`);

	getDocs(collectionRef).then(async collectionSnap => {
		let statslist: any = [];
		collectionSnap.forEach(doc => {
			statslist = [...statslist, doc.data()];
		});

		const newStats: Stats = {};

		statslist.forEach((stat: any) => {
			if (stat.uid && stat.shop_id && stat.tenant_id && stat.docid) {
				const docid = stat.docid;

				// we don't want duplication in the state
				delete stat.uid;
				delete stat.shop_id;
				delete stat.tenant_id;
				delete stat.docid;

				newStats[docid] = stat[docid];
			}
		});

		statsLoadedFromServer = { ...newStats };
		statsStore$.next(newStats);
	});
}

//we have had situations with empty keys in the object (test-data) - this function is to make it more robust
function removeInvalidKeys(data: { [key: string]: any }): object {
	const newObject: { [key: string]: any } = {};
	Object.keys(data)
		.filter(key => key !== '')
		.forEach(key => (newObject[key] = data[key]));
	return newObject;
}

export async function saveStatsToRemote() {
	if (!config.appConfig.experimental) return;

	// we are skipping this because we are not using it yet and we want to avoid errors
	const curStats = statsStore$.getValue();
	if (!curStats) return;

	const user = userStore$.getValue();
	if (!user) return;
	const shop = shopStore$.getValue();
	if (!shop) return;
	if (shop.length == 0) return;

	const batch = writeBatch(firestore);
	if (user !== undefined) {
		Object.keys(curStats).forEach(key => {
			if (!deepEqual(curStats[key], statsLoadedFromServer[key])) {
				const stat = { ...curStats[key] };
				stat.uid = user.uid;
				stat.tenant_id = user.active_tenant_id;
				stat.docid = key;
				stat.shop_id = shop[0]._id;

				const docToWrite = {
					[key]: removeInvalidKeys(curStats[key]),
					uid: user.uid,
					tenant_id: user.active_tenant_id,
					docid: key,
					shop_id: shop[0]._id,
				};

				const docRef1 = doc(firestore, `/users/${user.uid}/stats`, key);
				batch.set(docRef1, docToWrite);
			}
		});
	}
	return batch.commit();
}

/**
 * Sets the count for a given stats key and updates the stats store.
 * @param key - The stats key to update.
 * @param amount - The new count for the stats key.
 */
export function setStatsKeyCount(key: StatsKeys, amount: number) {
	if (!config.appConfig.experimental) return;

	// small helper function within blockscope
	function setIfEmpty(category: string, key: string, value: any) {
		if (!curStats[category]) curStats[category] = {};
		curStats[category][key] = value;
	}

	if (!amount) return;
	const curStats = { ...statsStore$.getValue() };
	if (!curStats) return;

	// set absolute value
	curStats[key] = amount;

	// timeseries
	const now = Date.now();
	const year = getYearFromEpoch(now);
	const weeknr = getWeekNumberFromEpoch(now);
	const year_weeknr = `${year}${formatNumberWithLeadingZero(weeknr)}`;
	const fullyearday = getYearMonthDayFromEpoch(now);

	setIfEmpty(year, key, amount);
	setIfEmpty(year_weeknr, key, amount);
	setIfEmpty(fullyearday, key, amount);

	nextWithPossibleSave(curStats);
}

/**
 * Sets the value of a given key in the stats store.
 * @param key - The key to set the value for.
 * @param value - The value to set for the key.
 */
export function setStatsKeyObject(key: StatsKeys, value: any) {
	if (!config.appConfig.experimental) return;

	// small helper function within blockscope
	function setIfEmpty(category: string, key: string, value: any) {
		if (!curStats[category]) curStats[category] = {};
		curStats[category][key] = value;
	}

	const curStats = { ...statsStore$.getValue() };
	if (!curStats) return;

	// set absolute value
	curStats[key] = value;

	// timeseries
	const now = Date.now();
	const year = getYearFromEpoch(now);
	const weeknr = getWeekNumberFromEpoch(now);
	const year_weeknr = `${year}${formatNumberWithLeadingZero(weeknr)}`;
	const fullyearday = getYearMonthDayFromEpoch(now);

	setIfEmpty(year, key, value);
	setIfEmpty(year_weeknr, key, value);
	setIfEmpty(fullyearday, key, value);

	nextWithPossibleSave(curStats);
}

/*
  we don't want to save stats we set in the state all the time, unless we are in dev or when the time has ticked
*/
let lastStatsSave = Date.now();
let statsUpdateCount = 0;

const maxStatsUpdateCount = 10;
const maxStatsUpdateDelay = 1000 * 60 * 5;

function nextWithPossibleSave(curStats: Stats) {
	if (!config.appConfig.experimental) return;

	statsStore$.next(curStats);

	let doSave = dev; // in de we always look to save to server
	statsUpdateCount = statsUpdateCount + 1;

	if (Date.now() - lastStatsSave > maxStatsUpdateDelay) doSave = true;
	if (statsUpdateCount > maxStatsUpdateCount) doSave = true;

	if (doSave) {
		lastStatsSave = Date.now();
		statsUpdateCount = 0;
		saveStatsToRemote();
	}
}
