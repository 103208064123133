export enum StatsKeys {
	LOGIN_count = 'Login_count',

	SHOP_count = 'Shop_count',

	FARMER_count = 'Farmer_count',

	CROP_count = 'Crop_count',

	CAMPAIGN_count = 'Campaign_count',
	CAMPAIGN_title_object = 'Campaign_title_object',
	CAMPAIGN_msg_count = 'Campaign_msg_count',
	CAMPAIGN_msg_send_self_count = 'Campaign_msg_send_self_count',
	CAMPAIGN_msg_send_count_buckets_object = 'CAMPAIGN_msg_send_count_buckets_object',
}
