import type { AppConfig } from './config-client.schema';

export const config: AppConfig = {
	appDomain: 'https://sidc.pro',

	countryCode: '+63',
	localeGroup: 'sidc',
	availableLocales: ['tg'],
	defaultLocale: 'tg',

	allowedNumbers: [],

	pwaItems: {
		name: 'SIDC Pro',
		short_name: 'SIDC Pro',
		description: 'Sell more! Achieve More!',
		theme_color: '#006633',
	},

	maxSMSLength: 160,

	// firebaseConfig: {
	// 	apiKey: 'AIzaSyDEmYcjc0lPrH_6TNdva3BTDiDTyDkbBQI',
	// 	authDomain: 'sidcfeed.firebaseapp.com',
	// 	projectId: 'sidcfeed',
	// 	storageBucket: 'sidcfeed.appspot.com',
	// 	messagingSenderId: '4856641661',
	// 	appId: '1:4856641661:web:64e8af52b459046f139914',
	// 	measurementId: 'G-Z1JMQE5LSV',
	// 	vapidKey: 'BP7W6Y4IxZumwztHfez9mHL1TdNZ4ePkOHJjnMg8S1-9j8qZ5JcsDvYl7phzJtB52E1kxA-5yt-J0IHCBfbTEA8',
	// 	recaptchaV3publicKey: '6LcnBVYoAAAAAJ3dBuFM-blZIvydEbz-reyQ65Yg',
	// },

	firebaseConfig: {
		apiKey: 'AIzaSyAOD7b8nzPDL4jEsQvn2CsXzwmccU0J7Bo',
		authDomain: 'sidc-db.firebaseapp.com',
		projectId: 'sidc-db',
		storageBucket: 'sidc-db.appspot.com',
		messagingSenderId: '171695035848',
		appId: '1:171695035848:web:41be21be1684ca1727dcfd',
		measurementId: 'G-0DM6HE9ZRV',
		vapidKey: '',
		recaptchaV3publicKey: '',
	},

	apiDev: 'http://127.0.0.1:5001/sidc-db/us-central1/',
	apiProd: 'https://us-central1-sidc-db.cloudfunctions.net/',
	// apiProd: 'https://us-central1-sidcfeed.cloudfunctions.net/',

	appConfig: {
		hasPrivacyNotice: false,
		hasTandC: false,
		experimental: false,
		isSIDC: true,
		shopActionsConfig: {
			userTooLongInactive: 7 * 24 * 60 * 60 * 1000, // one week
		},
		wantsMiddleName: true,
		systemDown: false,
		maxFreeTextLength: 150,
	},
	tenants: [
		{
			id: '1',
			short_title: 'SIDCFeeds',
		},
	],
};

export enum ShopType {
	AGRIBUSINESS = 'AGRIBUSINESS', // Outlet
	RETAIL = 'RETAIL', // Coop Store
	WHOLESALE = 'WHOLESALE', // Koopinoy
	TENANT = 'TENANT', // Admin
	SALESREP = 'SALESREP', // Sales Rep
	DEALER = 'DEALER', // Dealer
}
