import { BehaviorSubject } from 'rxjs';
import cropsDataBaseJSON from '../../assets/crops-library.json';
import { Crop } from './crop.schema';

import { config } from '../../assets/config';

import { provideListDataStore } from '../Helpers/load-listener';

// if we are in multi-tenant and there is a multi-tenant defined, we give that path
function getDocPath(tenant_id: string | undefined) {
	// if there is a user-defined tenant, we use that one
	if (tenant_id && tenant_id.length > 0) return `tenants/${tenant_id}/crops`;
	if (config.tenants.length == 1) return `tenants/${config.tenants[0].id}/crops`;

	// there is no user defined nor default tenant, fallback to legacy config
	return `crops`;
}

const dataType = 'crops';
const dataDocName = 'crop_list';

export const cropsDatabase = cropsDataBaseJSON as Crop[];
export let saveNewCropList = async (newCrops: Crop[]): Promise<void> => {};
export let logOutCropStore = () => {};
export let cropsStore$ = new BehaviorSubject<Crop[]>([]);

export function loadCropsAndSubscribe(tenant_id: string | undefined) {
	const dataStore = provideListDataStore<Crop>(dataType, dataDocName, cropsDatabase, getDocPath(tenant_id));

	saveNewCropList = dataStore.newListFn;
	cropsStore$ = dataStore.store$;
	logOutCropStore = dataStore.logOutFn;
}
