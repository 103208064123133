import { Context, createContext, useReducer } from 'react';
import { TemplateReducerActionInterface } from './simple-messages-reducer-action.schema';
import { SimpleMessagesActionsEnum } from './simple-messages-actions.enum';
import { SimpleMessagesContextInterface } from './simple-messages-context.schema';
import { Farmer } from '../../Farmers/farmer.schema';
import { User } from '../../User/user.schema';
import { TemplateV2 } from '../../Helpers/templates/templateV2.schema';

const defaultState: SimpleMessagesContextInterface = {
	templates: [] as TemplateV2[],
	setTemplates: () => {},
	selectedTemplate: {} as TemplateV2,
	setSelectedTemplate: () => {},
	farmers: [] as Farmer[],
	setFarmers: () => {},
	selectedFarmers: [] as Farmer[],
	setSelectedFarmers: () => {},
	sender: {} as any,
	setSender: () => {},
	sendToSelf: false,
	setSendToSelf: () => {},
	customMessage: false,
	setCustomMessage: () => {},
	templateEdited: false,
	setTemplateEdited: () => {},
	clearState: () => {},
};

export const SimpleMessagesContext: Context<SimpleMessagesContextInterface> = createContext<SimpleMessagesContextInterface>(defaultState);

function simpleMessagesReducer(state: SimpleMessagesContextInterface, action: TemplateReducerActionInterface) {
	if (action.type === SimpleMessagesActionsEnum.SET_SELECTED_TEMPLATE) {
		const selectedTemplate = { ...action.payload };

		return {
			...state,
			selectedTemplate,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_TEMPLATES) {
		const templates = [...action.payload];

		return {
			...state,
			templates,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_FARMERS) {
		const farmers = [...action.payload];

		return {
			...state,
			farmers,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_SELECTED_FARMERS) {
		const selectedFarmers = [...action.payload];

		return {
			...state,
			selectedFarmers,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_SEND_TO_SELF) {
		const sendToSelf = action.payload;

		return {
			...state,
			sendToSelf,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_SENDER) {
		const sender = { ...action.payload };

		return {
			...state,
			sender,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_CUSTOM_MESSAGE) {
		const customMessage = action.payload;

		return {
			...state,
			customMessage,
		};
	} else if (action.type === SimpleMessagesActionsEnum.SET_TEMPLATE_EDITED) {
		const templateEdited = action.payload;

		return {
			...state,
			templateEdited,
		};
	} else if (action.type === SimpleMessagesActionsEnum.CLEAR_STATE) {
		return defaultState;
	}

	return state;
}

export default function SimpleMessagesContextProvider({ children }: any) {
	const [simpleMessagesState, simpleMessagesDispatch] = useReducer(simpleMessagesReducer, defaultState);

	function handleSetSelectedTemplate(template: TemplateV2) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_SELECTED_TEMPLATE,
			payload: template,
		});
	}

	function handleSetTemplates(templates: TemplateV2[]) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_TEMPLATES,
			payload: templates,
		});
	}

	function handleSetFarmers(farmers: Farmer[]) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_FARMERS,
			payload: farmers,
		});
	}

	function handleSetSelectedFarmers(farmers: Farmer[]) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_SELECTED_FARMERS,
			payload: farmers,
		});
	}

	function handleSetSender(sender: User) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_SENDER,
			payload: sender,
		});
	}

	function handleSetSendToSelf(sendToSelf: boolean) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_SEND_TO_SELF,
			payload: sendToSelf,
		});
	}

	function handleSetCustomMessage(customMessage: boolean) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_CUSTOM_MESSAGE,
			payload: customMessage,
		});
	}

	function handleSetTemplateEdited(templateEdited: boolean) {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.SET_TEMPLATE_EDITED,
			payload: templateEdited,
		});
	}

	function handleClearState() {
		simpleMessagesDispatch({
			type: SimpleMessagesActionsEnum.CLEAR_STATE,
			payload: undefined,
		});
	}

	const contextValue: SimpleMessagesContextInterface = {
		templates: simpleMessagesState.templates,
		setTemplates: handleSetTemplates,
		selectedTemplate: simpleMessagesState.selectedTemplate,
		setSelectedTemplate: handleSetSelectedTemplate,
		farmers: simpleMessagesState.farmers,
		setFarmers: handleSetFarmers,
		selectedFarmers: simpleMessagesState.selectedFarmers,
		setSelectedFarmers: handleSetSelectedFarmers,
		sender: simpleMessagesState.sender,
		setSender: handleSetSender,
		sendToSelf: simpleMessagesState.sendToSelf,
		setSendToSelf: handleSetSendToSelf,
		customMessage: simpleMessagesState.customMessage,
		setCustomMessage: handleSetCustomMessage,
		templateEdited: simpleMessagesState.templateEdited,
		setTemplateEdited: handleSetTemplateEdited,
		clearState: handleClearState,
	};

	return <SimpleMessagesContext.Provider value={contextValue}>{children}</SimpleMessagesContext.Provider>;
}
